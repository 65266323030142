body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
  font-family: var(--font-base);
  background-color: var(--color-bg);
  color: var(--color-grey-100);
  background-image: url('./assets/pattern.png');
}

div:root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
}
