.swiper {
  max-width: min(90%, 810px);
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  --swiper-pagination-color: white;
  --swiper-navigation-color:white;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

.mobile {
  width: 320px;
  max-width: min(90%, 900px);
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  --swiper-pagination-color: white;
  --swiper-navigation-color:white;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-width: 10px;
  --swiper-pagination-bullet-height: 10px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

.swiper-slide {
  text-align: center;
  font-size: '1rem';
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
