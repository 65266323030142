.container {
  /* display: grid; */
  /* place-items: center; */
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
  width: 100%;
  background: linear-gradient(to bottom, var(--color-bg), transparent, var(--color-bg)),
    radial-gradient(circle, transparent 0%, var(--color-bg) 70%);
}

.main {
  display: flex;
  place-items: center;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
  width: 100%;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.wrapper {
  position: relative;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-lg);
}

.wrapper::after {
  position: absolute;
  content: '';
  top: 10%;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  filter: blur(30vh);
  background: linear-gradient(95deg, #7baff3, #3f8ef6, #0b2c56, #4e70d0);
  background-size: 150% 150%;
  /* animation: animateGlow 20s linear infinite; */
}

.wrapper li {
  color: var(--color-grey-400);
  line-height: 1.75;
}

.wrapper li:not(:first-child) {
  margin-top: 1vh;
}

.highlight {
  position: relative;
}

.highlightSelected {
  z-index: 5;
}

.highlightSelected::after {
  opacity: 0.5 !important;
}

.highlight::after {
  opacity: 0;
}

.highlight::after {
  transition: opacity 0.2s;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  filter: blur(15px);
  background: linear-gradient(95deg, #7baff3, #3f8ef6, #0b2c56, #4e70d0);
  background-size: 200% 200%;
  /* animation: animateGlow 5s linear infinite; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh;
  padding-bottom: 1vh;
}

.header .buttons {
  display: flex;
  align-items: center;
  gap: 0.5vh;
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

wui-image wui-icon-box {
  width: 12px !important;
  height: 12px !important;
}