@keyframes spin {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -172;
  }
}

.container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
}

.loader {
  /* Add loader specific styles if needed */
}

.hexagon-path {
  stroke-dasharray: 54, 118;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
  animation: spin 1s linear infinite;
}

.paused {
  animation-play-state: paused;
}
