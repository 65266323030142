.App {
  text-align: center;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Avenir+Next:wght@400;500;600;700&family=Fira+Code:wght@400;700&family=Fira+Mono:wght@400;700&family=Manrope:wght@400;700&family=Roboto+Mono:wght@400;700&display=swap');

.root {
  height: 77%;
}

:root {
  --font-base: 'Avenir Next', 'Manrope', sans-serif;
  --font-mono: 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
  --h: 180;
  --s: 2%;
  --l: 20%;
  --color-bg: #0f0f0f;
  --color-grey-100: #fff;
  --color-grey-300: #e4e7e7;
  --color-grey-400: #c9cfcf;
  --color-grey-500: #9ea9a9;
  --color-grey-700: #7a8484;
  --color-grey-900: #3b4040;
  --color-grey-950: #151616;
  --color-primary: hsl(var(--h), var(--s), var(--l));
  --shadow-md: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  --shadow-lg: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  --rounded-sm: 1vh;
  --rounded-md: 1.5vh;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevents scrolling */
  font-family: 'Orbitron';
  letter-spacing: 0.5px;
  font-size: '1rem';
  background-color: var(--color-bg);
  color: white;
  background-image: url('./assets/pattern.png');
}

pre,
code {
  font-family: 'Orbitron';
}

code {
  background-color: var(--color-grey-950);
}

code:before,
code:after {
  content: '`';
}

ul,
li {
  list-style-position: inside;
}

header {
  /* position: fixed; */
  width: 100%;
}

wui-image wui-icon-box {
  width: 12px !important;
  height: 12px !important;
}

/* clamp(16px, 2vw, 22px);
fontSize: {
  xl: '2.4vh',
  xl2: '2.8vh',
  xl3: '3vh',
  xl4: '3.5vh',
  lg: '2.0vh',
  md: '1.7vh',
  sm: '1.4vh',
}, */
:root {
  --w3m-font-size-master: 8px !important;

  --joy-fontSize-xs: var(--w3m-font-size-master) !important;
  --joy-fontSize-sm: calc(var(--w3m-font-size-master) * 1.4) !important;
  --joy-fontSize-md: calc(var(--w3m-font-size-master) * 1.6) !important;
  --joy-fontSize-lg: calc(var(--w3m-font-size-master)* 1.8) !important;
  --joy-fontSize-xl: calc(var(--w3m-font-size-master) * 2) !important;
  --joy-fontSize-xl2: calc(var(--w3m-font-size-master) * 2.5) !important;
  --joy-fontSize-xl3: calc(var(--w3m-font-size-master) * 3) !important;
  --joy-fontSize-xl4: calc(var(--w3m-font-size-master) * 4) !important;

  --w3m-color-mix-strength: 0%;
  --w3m-font-family: Orbitron;
  
  --w3m-border-radius-master: 4px;
  --w3m-z-index: 100;
  --wui-font-family: var(--w3m-font-family);
  --wui-font-size-micro: var(--w3m-font-size-master);
  --wui-font-size-tiny: calc(var(--w3m-font-size-master) * 1.2);
  --wui-font-size-small: calc(var(--w3m-font-size-master) * 1.4);
  --wui-font-size-paragraph: calc(var(--w3m-font-size-master) * 1.6);
  --wui-font-size-large: calc(var(--w3m-font-size-master) * 2);
  --wui-border-radius-5xs: var(--w3m-border-radius-master);
  --wui-border-radius-4xs: calc(var(--w3m-border-radius-master) * 1.5);
  --wui-border-radius-3xs: calc(var(--w3m-border-radius-master) * 2);
  --wui-border-radius-xxs: calc(var(--w3m-border-radius-master) * 3);
  --wui-border-radius-xs: calc(var(--w3m-border-radius-master) * 4);
  --wui-border-radius-s: calc(var(--w3m-border-radius-master) * 5);
  --wui-border-radius-m: calc(var(--w3m-border-radius-master) * 7);
  --wui-border-radius-l: calc(var(--w3m-border-radius-master) * 9);
  --wui-border-radius-3xl: calc(var(--w3m-border-radius-master) * 20);
  --wui-font-weight-light: 400;
  --wui-font-weight-regular: 500;
  --wui-font-weight-medium: 600;
  --wui-font-weight-bold: 700;
  --wui-letter-spacing-large: 1px !important;
  --wui-letter-spacing-paragraph: 0.5px !important;
  --wui-letter-spacing-small: 1px !important;
  --wui-letter-spacing-tiny: 1px !important;
  --wui-letter-spacing-micro: 1px !important;
  --wui-spacing-0: 0px;
  --wui-spacing-4xs: 2px;
  --wui-spacing-3xs: 4px;
  --wui-spacing-xxs: 6px;
  --wui-spacing-2xs: 7px;
  --wui-spacing-xs: 8px;
  --wui-spacing-1xs: 10px;
  --wui-spacing-s: 12px;
  --wui-spacing-m: 14px;
  --wui-spacing-l: 16px;
  --wui-spacing-2l: 18px;
  --wui-spacing-xl: 20px;
  --wui-spacing-xxl: 24px;
  --wui-spacing-2xl: 32px;
  --wui-spacing-3xl: 40px;
  --wui-spacing-4xl: 90px;
  --wui-icon-box-size-xxs: 14px;
  --wui-icon-box-size-xs: 20px;
  --wui-icon-box-size-sm: 24px;
  --wui-icon-box-size-md: 32px;
  --wui-icon-box-size-lg: 40px;
  --wui-icon-box-size-xl: 64px;
  --wui-icon-size-inherit: inherit;
  --wui-icon-size-xxs: 10px;
  --wui-icon-size-xs: 12px;
  --wui-icon-size-sm: 14px;
  --wui-icon-size-md: 16px;
  --wui-icon-size-mdl: 18px;
  --wui-icon-size-lg: 20px;
  --wui-icon-size-xl: 24px;
  --wui-wallet-image-size-inherit: inherit;
  --wui-wallet-image-size-sm: 40px;
  --wui-wallet-image-size-md: 56px;
  --wui-wallet-image-size-lg: 80px;
  --wui-box-size-md: 100px;
  --wui-box-size-lg: 120px;
  --wui-ease-out-power-2: cubic-bezier(0, 0, 0.22, 1);
  --wui-ease-out-power-1: cubic-bezier(0, 0, 0.55, 1);
  --wui-ease-in-power-3: cubic-bezier(0.66, 0, 1, 1);
  --wui-ease-in-power-2: cubic-bezier(0.45, 0, 1, 1);
  --wui-ease-in-power-1: cubic-bezier(0.3, 0, 1, 1);
  --wui-ease-inout-power-1: cubic-bezier(0.45, 0, 0.55, 1);
  --wui-duration-lg: 200ms;
  --wui-duration-md: 125ms;
  --wui-duration-sm: 75ms;
  --wui-color-inherit: inherit;
  --wui-color-inverse-100: #fff;
  --wui-color-inverse-000: #000;
  --wui-cover: rgba(20, 20, 20, 0.8);
  --wui-color-modal-bg: var(--wui-color-modal-bg-base);
  --wui-color-blue-100: var(--wui-color-blue-base-100);
  --wui-color-accent-100: var(--wui-color-accent-base-100);
  --wui-color-accent-090: var(--wui-color-accent-base-090);
  --wui-color-accent-080: var(--wui-color-accent-base-080);
  --wui-accent-glass-090: var(--wui-accent-glass-base-090);
  --wui-accent-glass-080: var(--wui-accent-glass-base-080);
  --wui-accent-glass-020: var(--wui-accent-glass-base-020);
  --wui-accent-glass-015: var(--wui-accent-glass-base-015);
  --wui-accent-glass-010: var(--wui-accent-glass-base-010);
  --wui-accent-glass-005: var(--wui-accent-glass-base-005);
  --wui-accent-glass-002: var(--wui-accent-glass-base-002);
  --wui-color-fg-100: var(--wui-color-fg-base-100);
  --wui-color-fg-125: var(--wui-color-fg-base-125);
  --wui-color-fg-150: var(--wui-color-fg-base-150);
  --wui-color-fg-175: var(--wui-color-fg-base-175);
  --wui-color-fg-200: var(--wui-color-fg-base-200);
  --wui-color-fg-225: var(--wui-color-fg-base-225);
  --wui-color-fg-250: var(--wui-color-fg-base-250);
  --wui-color-fg-275: var(--wui-color-fg-base-275);
  --wui-color-fg-300: var(--wui-color-fg-base-300);
  --wui-color-bg-100: var(--wui-color-bg-base-100);
  --wui-color-bg-125: var(--wui-color-bg-base-125);
  --wui-color-bg-150: var(--wui-color-bg-base-150);
  --wui-color-bg-175: var(--wui-color-bg-base-175);
  --wui-color-bg-200: var(--wui-color-bg-base-200);
  --wui-color-bg-225: var(--wui-color-bg-base-225);
  --wui-color-bg-250: var(--wui-color-bg-base-250);
  --wui-color-bg-275: var(--wui-color-bg-base-275);
  --wui-color-bg-300: var(--wui-color-bg-base-300);
  --wui-color-success-100: var(--wui-color-success-base-100);
  --wui-color-error-100: var(--wui-color-error-base-100);
  --wui-icon-box-bg-error-100: var(--wui-icon-box-bg-error-base-100);
  --wui-icon-box-bg-blue-100: var(--wui-icon-box-bg-blue-base-100);
  --wui-icon-box-bg-success-100: var(--wui-icon-box-bg-success-base-100);
  --wui-icon-box-bg-inverse-100: var(--wui-icon-box-bg-inverse-base-100);
  --wui-all-wallets-bg-100: var(--wui-all-wallets-bg-base-100);
  --wui-avatar-border: var(--wui-avatar-border-base);
  --wui-thumbnail-border: var(--wui-thumbnail-border-base);
  --wui-box-shadow-blue: rgba(71, 161, 255, 0.16);
}
