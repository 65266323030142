.glow {
  overflow: hidden;
  width: 100;
  height: 100%;
}

.glow::after {
  position: absolute;
  content: '';
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 0%;
  border-radius: 50%;
  /* transform: translate(-50%, -50%); */
  /* box-shadow: 0 0 1vh 0.5vh rgba(255, 255, 255, 0.5), 0 0 2vh 2vh rgba(255, 255, 255, 0.3),
    0 0 3vh 3vh rgba(255, 255, 255, 0.2), 0 0 4vh 4vh rgba(255, 255, 255, 0.1), 0 0 5vh 5vh rgba(255, 255, 255, 0.05); */
  animation: glowEffect 1.2s infinite;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.4) 0%, transparent 70%);
}

@keyframes glowEffect {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* @keyframes glowEffect {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
  50% {
    box-shadow: 0 0 1vh 0.5vh rgba(255, 255, 255, 0.5), 0 0 2vh 2vh rgba(255, 255, 255, 0.3),
      0 0 3vh 3vh rgba(255, 255, 255, 0.2), 0 0 4vh 4vh rgba(255, 255, 255, 0.1), 0 0 5vh 5vh rgba(255, 255, 255, 0.05);
  }
  90% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
} */

.highlight::after {
  transition: opacity 0.1s;
  position: absolute;

  content: '';
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  border: 'solid';
  border-radius: 50%;
  filter: blur(20px);
  background: linear-gradient(95deg, rgb(224, 224, 224), #7b7d80, #232324, #171718);
  background-size: 200% 200%;
  /* animation: animateGlow 1.5s linear infinite; */
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
